<template>
 <div>
  <router-view />
</div>
</template>

<script>

export default {
  name: 'basicSetting',
  components: {
  }
}
</script>
<style lang="less" scoped>
</style>
